import styled from 'styled-components'

export const ContainerHowAre = styled.div`
    .bannerHowAre {
        height: 350px;
        width: 100%;
        background-image: ${(props) => `url(${props.backgroundImage})`};
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;

        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: flex-end;
    }

    .wrapperNumbersHowAre {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        text-align: center;

        margin: 50px 0 0;

        .itemNumberHowAre {
            flex-basis: 25%;
            h3 {
                font-size: 32px;
            }
        }

        @media only screen and (max-width: 798px) {
            .itemNumberHowAre {
                flex-basis: 50%;
                margin-bottom: 30px;
            }
        }
    }

    .wrapperCardHowAre {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: center;
    }
`
