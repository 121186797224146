import React from 'react'
import { useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import AboutLayout from '../../components/Layout/About'

import { ContainerHowAre } from '../../styles/howAre'
import { CardExplanatory } from '../../components/cards'

import missionIcon from '../../assets/images/icons/about/missionIcon.svg'
import visionIcon from '../../assets/images/icons/about/visionIcon.svg'
import valuesIcon from '../../assets/images/icons/about/valuesIcon.svg'

const HowArePage = () => {
    const { ourHistory } = useStaticQuery(graphql`
        query {
            ourHistory: allStrapiSobre {
                nodes {
                    id
                    history
                    history_image {
                        publicURL
                    }
                }
            }
        }
    `)

    const _contentItem = [
        {
            id: 1,
            content: (
                <p style={{ textAlign: 'justify', lineHeight: '25px' }}>
                    Promover a marca dos nossos produtos e dar suporte na manutenção industrial,
                    vendas de equipamentos, peças e consumíveis, garantindo a funcionalidade dos
                    processos dos nossos clientes.
                </p>
            ),
            img: { data: missionIcon, alt: 'Missão - Minas Coding' },
            title: 'Missão',
        },
        {
            id: 2,
            content: (
                <p style={{ textAlign: 'justify', lineHeight: '30px' }}>
                    Ser referência no mercado que atuamos, aumentando os volumes de negócios, sempre
                    com ética, eficiência e aprimoramento contínuo.
                </p>
            ),
            img: { data: visionIcon, alt: 'Visão - Minas Coding' },
            title: 'Visão',
        },
        {
            id: 3,
            content: (
                <ul style={{ textAlign: 'justify', lineHeight: '30px' }}>
                    <li>Conduta ética e profissional</li>
                    <li>Excelência no atendimento</li>
                    <li>Eficiência</li>
                    <li>Comprometimento</li>
                    <li>Respeito</li>
                </ul>
            ),
            img: { data: valuesIcon, alt: 'Valores - Minas Coding' },
            title: 'Valores',
        },
    ]

    return (
        <AboutLayout type="how-are">
            <ContainerHowAre backgroundImage={ourHistory.nodes[0]?.history_image.publicURL}>
                <div className="backgroundContentAbout mb-2">
                    <h1 className="font-subtitle ">Nossa história</h1>
                </div>
                <div className="bannerHowAre"></div>

                <ReactMarkdown className="text-about">{ourHistory.nodes[0]?.history}</ReactMarkdown>

                <div className="backgroundContentAbout mt-3px mb-2">
                    <h2 className="font-subtitle">Missão,Visão e Valores</h2>
                </div>

                <div className="wrapperCardHowAre mt-3px">
                    {_contentItem.map((i) => (
                        <div key={i.id} className="itemCardHowAre">
                            <CardExplanatory
                                img={{ data: i.img.data, alt: i.img.alt }}
                                title={i.title}
                                content={i.content}
                            />
                        </div>
                    ))}
                </div>
            </ContainerHowAre>
        </AboutLayout>
    )
}

export default HowArePage
